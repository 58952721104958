<template>
  <div class="news-page">
    <!-- <top-box :option="3"></top-box> -->
    <div class="bannar">
      <!-- <img src="../../image/bg.png"> -->
      <!-- <img v-if="bannerSrc" :src="bannerSrc">
            <img v-else src="../../image/bg.png"> -->
      <img :src="bannerSrc" v-if="bannerType" />
      <video
        v-else
        muted="muted"
        id="startvideo"
        class="video"
        :src="bannerSrc"
        type="video/mp4"
        autoplay="autoplay"
        controls="controls"
        loop="-1"
      >
        <p>你的浏览器不支持video标签.</p>
      </video>
      <div class="overlay"></div>
      <div class="bannar-info">
        <div class="bannar-title">天府国际赛道</div>
        <div class="bannar-detail">{{ bannerTitle }}</div>
      </div>
    </div>
    <main>
      <div class="main-content">
        <div
          v-for="(item, index) in businessList"
          :key="index"
          class="main-content-item"
          @click="detailsClick(item.id)"
        >
          <div class="main-content-item-img">
            <img :src="item.imgSrc" :title="item.name" />
          </div>
          <div class="info">
            <div class="title">{{ item.name }}</div>
            <div class="content" v-html="item.content"></div>
            <div class="time">{{ item.releaseTime }}</div>
            <div class="detail">
              <span>{{ $t("home.more") }}</span>
              <img src="../../../image/home/right2.png" />
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- <bottom-box></bottom-box> -->
  </div>
</template>
<script>
import { getBanner, getBusiness } from "../../../api/homeApi";
export default {
  data() {
    return {
      bannerSrc: "",
      bannerType: 1, //1.图片 0.视频
      bannerTitle: "",
      businessList: [],
      total: 0,
      pageNum: 1,
      type: 0,
    };
  },
  created() {
    this.getBanner();
    this.getBusiness();
  },
  methods: {
    optionClick(val) {
      this.optionIdx = val;
      this.type = this.optionList[val].id;
      this.pageNum = 1;
      if (this.type == "") {
        this.getVideos();
      } else {
        this.getNew();
      }
      // this.getNew()
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
      );
      return flag;
    },
    getBanner() {
      getBanner({
        plate: 0,
      }).then((res) => {
        if (res.code == 0) {
          if (res.infos.length > 0) {
            if (this._isMobile()) {
              this.bannerType = 1;
              this.bannerSrc = this.formatImageUrl(
                JSON.parse(res.infos[0].mobileImage)[0].raw
              );
            } else {
              let arr = JSON.parse(res.infos[0].image);
              if (
                [
                  "image/png", // .png
                  "image/jpeg", // .jpg .jpeg
                  "image/x-ms-bmp", // .bmp
                  "image/gif", // .gif
                  "image/tiff", // .tif .tiff
                ].includes(arr[0].type)
              ) {
                this.bannerType = 1;
              } else {
                this.bannerType = 0;
              }
              this.bannerSrc = this.formatImageUrl(
                JSON.parse(res.infos[0].image)[0].raw
              );
            }
            this.bannerTitle = res.infos[0].content;
          }
        }
      });
    },

    // 获取业务
    getBusiness() {
      getBusiness().then((res) => {
        if (res.code == 0) {
          this.businessList = res.infos;
          res.infos.forEach((item, idx) => {
            item.imgSrc = this.formatImageUrl(JSON.parse(item.image)[0].raw);
          });
        }
      });
    },
    detailsClick(id) {
      this.$router.push(`/home/business/${id}`);
    },
  },
};
</script>
<style lang="less" scoped>
@media screen and(max-width: 900px) {
  .news-page {
    .bannar {
      width: 100%;
      // height: 5.5rem;
      background: #666;
      margin-top: 20px;
      position: relative;
      .overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.4);
        z-index: 9;
      }
      img {
        vertical-align: middle;
        height: 30vw;
        width: 100%;
      }
      .bannar-info {
        position: absolute;
        top: 20%;
        left: 0;
        width: 100%;
        text-align: center;
        padding: 0 20px;
        .bannar-title {
          font-size: 36px;
          color: #fff;
          margin-bottom: 20px;
        }
        .bannar-detail {
          font-size: 24px;
          color: #fff;
        }
      }
    }
    main {
      box-sizing: border-box;
      // width: 1200px;
      padding: 0.8rem 0rem;
      background-color: #fff;
      margin: 0 auto;
      .main-option {
        display: flex;
        justify-content: center;
        margin-bottom: 0.6rem;
        .main-option-item:nth-last-child(1) {
          margin-right: 0;
        }
        .main-option-item {
          width: 1.2rem;
          height: 0.5rem;
          background-color: #f1f1f1;
          font-size: 0.18rem;
          color: #666;
          text-align: center;
          line-height: 0.5rem;
          margin-right: 0.6rem;
          cursor: pointer;
          border-radius: 0.04rem;
        }
        .active {
          background-color: #ee6a23;
          color: #fff;
        }
      }
      .main-content {
        padding: 0 20px;
        box-sizing: border-box;
        .main-content-item:nth-last-child(1) {
          margin-bottom: 0;
        }
        .main-content-item {
          width: 100%;
          display: flex;
          margin-bottom: 0.4rem;
          cursor: pointer;
          .main-content-item-img {
            width: 50%;
            // height: 100%;
            flex-shrink: 0;
            img {
              width: 100%;
              height: 100%;
              // height: 2.1rem;
              background-color: #fff;
              display: block;
              // flex-shrink: 0;
            }
          }

          .info {
            box-sizing: border-box;
            padding: 0.3rem 0.4rem;
            border: 1px solid #e6e6e6;
            width: 50%;
            .title {
              font-size: 24px;
              color: #333;
              margin-bottom: 0.1rem;
              font-weight: bold;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .content {
              font-size: 18px;
              line-height: 2;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
              overflow: hidden;
              color: #666;
              margin-bottom: 0.1rem;
              height: 110px;
              /deep/p {
                margin: 0;
                span {
                  font-size: 18px !important;
                }
              }
            }
            .time {
              font-size: 18px;
              color: #666;
            }
            .detail {
              display: flex;
              align-items: center;
              cursor: pointer;
              justify-content: flex-end;
              img {
                width: 8px;
                height: 14px;
                margin-left: 10px;
              }
            }
          }
        }
      }
      .main-vedios {
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        padding: 0 20px;

        .main-vedios-item {
          width: 100%;
          margin-bottom: 40px;
          position: relative;
          cursor: pointer;
          .vedios-img {
            img {
              height: 3.2rem;
              width: 100%;
              vertical-align: middle;
            }
          }
          .vedios-info {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            padding: 10px 20px;
            .title {
              font-size: 18px;
              flex: 1;
              margin-right: 20px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .time {
              font-size: 12px;
            }
          }
        }
      }
      .pagination {
        margin-top: 40px;
        text-align: center;
      }
    }
  }
}
@media screen and(min-width: 900px) {
  .news-page {
    .bannar {
      width: 100%;
      // height: 5.5rem;
      background: #666;
      position: relative;
      img {
        vertical-align: middle;
        height: 550px;
        width: 100%;
      }
      .bannar-info {
        position: absolute;
        top: 40%;
        left: 0;
        width: 100%;
        text-align: center;
        z-index: 9;
        .bannar-title {
          font-size: 36px;
          color: #fff;
          margin-bottom: 20px;
        }
        .bannar-detail {
          font-size: 24px;
          color: #fff;
        }
      }
    }
    main {
      box-sizing: border-box;
      width: 1200px;
      padding: 0.8rem 0rem;
      background-color: #fff;
      margin: 0 auto;
      .main-option {
        display: flex;
        justify-content: center;
        margin-bottom: 0.6rem;
        .main-option-item:nth-last-child(1) {
          margin-right: 0;
        }
        .main-option-item {
          width: 1.8rem;
          height: 0.5rem;
          background-color: #f1f1f1;
          font-size: 0.18rem;
          color: #666;
          text-align: center;
          line-height: 0.5rem;
          margin-right: 0.6rem;
          cursor: pointer;
          border-radius: 0.04rem;
        }
        .active {
          background-color: #ee6a23;
          color: #fff;
        }
      }
      .main-content {
        .main-content-item:nth-last-child(1) {
          margin-bottom: 0;
        }
        .main-content-item:hover {
          .info {
            background-color: #f1f1f1;
          }
        }
        .main-content-item {
          width: 100%;
          display: flex;
          margin-bottom: 0.4rem;
          cursor: pointer;
          .main-content-item-img {
            width: 3.2rem;
            height: 2.1rem;
            display: block;
            flex-shrink: 0;
            overflow: hidden;
            img {
              width: 3.2rem;
              height: 2.1rem;
              display: block;
              transition: 0.5s all;
            }
            img:hover {
              transition: 0.5s all;
              transform: scale(1.1);
            }
          }

          .info {
            box-sizing: border-box;
            padding: 0.3rem 0.4rem;
            border: 1px solid #e6e6e6;
            .title {
              font-size: 0.18rem;
              color: #333;
              margin-bottom: 0.1rem;
              font-weight: bold;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .content {
              font-size: 0.12rem;
              line-height: 2;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
              overflow: hidden;
              color: #666;
              margin-bottom: 0.1rem;
            }
            .time {
              font-size: 0.12rem;
              color: #666;
            }
          }
        }
      }
      .main-vedios {
        display: flex;
        flex-wrap: wrap;
        .main-vedios-item:nth-child(2n) {
          margin-right: 0;
        }
        .main-vedios-item {
          width: calc(50% - 20px);
          margin-right: 40px;
          margin-bottom: 40px;
          position: relative;
          cursor: pointer;
          .vedios-img {
            img {
              height: 3.2rem;
              width: 100%;
              vertical-align: middle;
            }
          }
          .vedios-info {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            padding: 10px 20px;
            .title {
              font-size: 18px;
              flex: 1;
              margin-right: 20px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .time {
              font-size: 12px;
            }
          }
        }
      }
      .pagination {
        margin-top: 40px;
        text-align: center;
      }
    }
  }
}
.news-page {
  /deep/.pagination {
    .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #ee6a23;
    }
    .el-pagination.is-background .el-pager li:not(.disabled):hover {
      color: #ee6a23;
    }
  }
}
</style>